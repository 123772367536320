import { makeStyles } from "@material-ui/core/styles";
import { firestore } from "../services/firebase/firebase";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import LoopIcon from '@mui/icons-material/Loop';
import firebase from "firebase/app";

const useStyles = makeStyles({
	feedbackContainer: {
		position: "relative",
		bottom: "-20px",
		left: '-25px',
		marginLeft: "auto",
		display: "flex",
		justifyContent: "flex-end",
	},
	feedbackBtn: {
		border: "2px solid currentColor",
		padding: "9px",
    backgroundColor: "white!important",
    position: "absolute",
    bottom: "2px",
    right: "-12px",
    height: "20px",
    width: "20px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

		"&:hover": {
			cursor: "pointer",
			opacity: "0.8",
		},
	},
	flag: {
		background: "var(--user-msg-txt)",
		border: "1px solid var(--color-alt-grey)",
		borderRadius: "5px",
		padding: "1px",
		color: "var(--color-light-grey)",
		height: "15px",
		width: "15px",
		transition: "color 0.15s ease",
	},
	flagSelected: {
		color: "var(--bot-status-disconnected)",
	},
});

export default function ResubmitRequestButton({ messageId, chatbotId, disabled, questionRef, user, setLoading, handleFallbackMessage }) {
	const classes = useStyles();

	const resubmitRequestCall = async () => {
		if (!messageId || !questionRef) {
      console.error("messageId or questionRef is not set.");
      return;
    }

		try {
		// Construct the room document ID
    const roomDocId = `${user.uid}***${chatbotId}`;

    // Reference to the specific room document
    const roomDocRef = firestore.collection("rooms").doc(roomDocId);

    // Fetch the room document snapshot
    const snapRoom = await roomDocRef.get();

    if (!snapRoom.exists) {
      console.error(`Room document with ID "${roomDocId}" does not exist.`);
      return;
    }

    // Get room data
    const roomData = snapRoom.data();

		// Extract 'key' and 'appKey' from room data
		const { key, appKey } = roomData;

		console.log('Key:', key);
		console.log('AppKey:', appKey);

		// Reference to the 'messages' subcollection
		const messagesRef = roomDocRef.collection("messages");

		// Reference to the specific message document identified by 'questionRef'
		const questionDocRef = messagesRef.doc(questionRef);

		// Reference to this specific message document identified by 'messageId'
		const messageIdRef = messagesRef.doc(messageId);

		// Fetch the question message document snapshot
		const snapQuestion = await questionDocRef.get();

		if (!snapQuestion.exists) {
			console.error(`Question message document with ID "${questionRef}" does not exist.`);
			return;
		}

		// Get question message data
		const questionData = snapQuestion.data();

		// Validate the 'text' field
		if (!questionData.hasOwnProperty('text') || !Array.isArray(questionData.text)) {
			console.error(`The 'text' field is missing or is not an array in message document "${questionRef}".`);
			return;
		}

		// Retrieve the first element of the 'text' array
		const firstText = questionData.text[0];

		// Check if 'text[0]' exists and is a non-empty string
		if (typeof firstText !== 'string' || firstText.trim() === '') {
			console.error(`'text[0]' is missing or not a valid string in message document "${questionRef}".`);
			return;
		}

		console.log(`First text from message "${questionRef}":`, firstText);
		setLoading(true) //to show the loader immediatly after the user submit the message
		const options = {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
			hour12: false, // 24-hour format
			timeZoneName: 'short',
			fractionalSecondDigits: 3 // Milliseconds
		};
		const formattedDate = new Date(Date.now()).toLocaleString('en-US', options);
		const dataToAdd = {
			parentUrl: document.referrer,
			text: firstText,
			createdAt: firebase.firestore.FieldValue.serverTimestamp(),
			uid: roomDocId,
			photoUrl:
				"https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/batman_hero_avatar_comics-512.png",
			localDateTime: formattedDate,
			isTimeKeeper: false
			};

		if (key !== undefined) {
			dataToAdd.key = key;
		}

		if (appKey !== undefined) {
			dataToAdd.appKey = appKey;
		}

		const res = await messagesRef.add(dataToAdd);
		const messageId1 = res.id; // Extract the message ID
    console.log("New message ID:", messageId1); // Log the message ID
		handleFallbackMessage(messageId1);

		//Set the resubmit button to disable it
		messageIdRef.set({
			resubmitRequestBtnDisabled: true,
		}, { merge: true })

	} catch (error) {
		console.error("Error in cancelRequestCall:", error);
		// Optionally, handle errors by notifying the user or retrying
	}
	};

	return (
		<div className={classes.feedbackContainer}>
			<Tooltip title="Resubmit request">
				<IconButton
					className={classes.feedbackBtn}
					onClick={() => {
						console.log("Resubmit request");
						resubmitRequestCall();
					}}
					disabled={disabled}
				>
					 <LoopIcon sx={{ fontSize: "16px" }} />
				</IconButton>
			</Tooltip>
		</div>
	);
}
