import { makeStyles } from "@material-ui/core/styles";
import smallRobotAvatar from "../images/robotSmall.svg";
import liveAgentAvatar from "../images/live-agent.svg";
import ResponseBtnGroup from "./ResponseBtnGroup.js";
import AnswerBtnGroup from "./AnswerBtnGroup.js";
import Box from "@material-ui/core/Box";
import FeedbackButton from "../components/FeedbackButton";
import CancelRequestButton from "../components/CancelRequestButton";
import ResubmitRequestButton from "../components/ResubmitRequestButton";
import { firestore } from "../services/firebase/firebase.js";
import { useEffect } from "react";
import Card from "./Card"
import { useStateValue } from "../context/state/provider";
import FormUserData from "../components/FormUserData";
import CustomCard from "./Card.js";
import PdfCard from "./PdfCard";
import Button from "@material-ui/core/Button";
import { VIEW } from "../constants/view.constants.js";
import { loginRequest } from "../helpers/authConfig";
import { useMsal } from "@azure/msal-react";

const useStyles = makeStyles({
	message: {
		width: "100%",
		fontSize: "1em",
		backgroundColor: "var(--user-msg-txt)",
		textAlign: "right",
		animation: "fadeIn 0.02s ease",
	},
	messageBody: {
		padding: "10px",
		backgroundColor: "var(--active-msg-bg)",
		color: "var(--user-msg-txt)",
		display: "inline-block",
		borderRadius: "6px",
		marginRight: "0px",
		marginLeft: "50px",

		textAlign: "left",
		overflowWrap: "break-word",
		maxWidth: "75%",
		"-moz-box-shadow": "0 0.5px 1px rgba(0, 0, 0, 0.28)",
		"-webkit-box-shadow": "0 0.5px 1px rgba(0, 0, 0, 0.28)",
		boxShadow: "0 0.5px 1px rgba(0, 0, 0, 0.28)",
		animation: "fadeIn 0.02s ease",
	},

	system: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		textAlign: "left",
		marginLeft: "8px", //to avoid side effect that shift all message when submiting a new one
	},
	systemBody: {
		backgroundColor: "var(--bot-msg-bg)",
		color: "var(--bot-msg-txt)",
		marginLeft: "0px",
		marginRight: "50px",
	},
	systemBodyWidth: {
		'& img': {
			maxWidth: "273px",
			height: "auto",
			border: "none",
			width: "100%",
		},
		'& video': {
			maxWidth: "273px",
			height: "auto",
			border: "none",
			width: "100%",
			display: 'block',
			clear: 'both',
		},
		'& iframe': {
			maxWidth: "273px",
			height: "auto",
			border: "none",
			width: "100%",
			display: 'block',
			clear: 'both',
		},
		// '& pre': {
		// 	overflowX: "auto;",
		// },
		// '& code': {
		// 	fontSize: '87.5%',
		// 	color: '#e83e8c',
		// 	wordWrap: 'break-word',
		// },
	},
	userName: {
		color: "var(--color-dark-grey)",
		fontSize: "calc(var(--active-font-size) - 3px)",
		display: "block",
		margin: "5px 3px 12px 0",
	},
	systemName: {
		color: "var(--color-dark-grey)",
		fontSize: "calc(var(--active-font-size) - 3px)",
		display: "block",
		textAlign: "start",
		margin: "5px 10px 12px 52px", //to avoid side effect that shift all message when submiting a new one
	},
	userPlaceholder: {
		height: "8px",
	},
	avatarPlaceholder: {
		marginLeft: "42px",
	},
	botLogo: {
		alignSelf: "flex-end",
		height: "30px",
		width: "30px",
		marginRight: "12px",
	},
	// botAvatar: {
  //   alignSelf: "flex-end",
  //   height: "30px",
  //   width: "30px",
  //   marginRight: "12px",
  //   backgroundImage: props => `url(${props.avatarUrl || smallRobotAvatar})`,
  //   backgroundPosition: "center",
  //   backgroundSize: "contain",
  //   borderRadius: "50%",
  // },
	botAvatar: {
    width: '30px',
    height: '30px',
    minWidth: '30px',
    borderRadius: '50%',
    marginRight: '12px',
    alignSelf: 'flex-end',
    objectFit: 'contain', // This helps maintain image aspect ratio
  },
	link: {
		color: "var(active-brand-color)",

		"&:hover": {
			opacity: "0.85",
			cursor: "pointer",
		},
	},
	date: {
		fontSize: "calc(var(--active-font-size) - 4px)",
		color: "var(--monochrome-text)",
		margin: "0px auto 8px auto",
	},
	dateLine: {
		width: "98%",
		height: "1px",
		background: "var(--color-light-grey)",
		margin: "auto",
		marginBottom: "15px",
	},
	messageBodyCard: {
		padding: "0px",
		backgroundColor: "transparent",
		color: "var(--user-msg-txt)",
		display: "inline-block",
		borderRadius: "6px",
		marginRight: "0px",
		marginLeft: "50px",
		textAlign: "left",
		overflowWrap: "break-word",
		maxWidth: "75%",
		"-moz-box-shadow": "0 0.5px 1px rgba(0, 0, 0, 0.28)",
		"-webkit-box-shadow": "0 0.5px 1px rgba(0, 0, 0, 0.28)",
		boxShadow: "0 0.5px 1px rgba(0, 0, 0, 0.28)",
		animation: "fadeIn 0.02s ease",
	},
	systemBodyCard: {
		backgroundColor: "transparent",
		color: "var(--bot-msg-txt)",
		marginLeft: "0px",
		marginRight: "50px",
	},


	"@media (max-width: 375px)": {
		messageBody: {
			marginLeft: "20px",
			fontSize: "calc(var(--active-font-size) - 2px)",
		},
		systemBody: {
			marginLeft: 0,
			marginRight: "20px",
		},
		messageBodyCard: {
			marginLeft: "20px",
			fontSize: "calc(var(--active-font-size) - 2px)",
		},
		systemBodyCard: {
			marginLeft: 0,
			marginRight: "20px",
		},
	},

	richeTextWrapper: {
		"& > p:first-child": {
			marginTop: "0px",
		},
		"& > p:last-child": {
			marginBottom: "0px"
		},
		"& pre": {
      whiteSpace: "pre-wrap",       // Allows long lines to wrap
      wordWrap: "break-word",       // Breaks long words to fit within the container
      wordBreak: "break-all",       // Breaks words at arbitrary points to prevent overflow
      maxWidth: "100%",             // Prevents the <pre> block from exceeding the container width
      overflow: "auto",             // Adds scrollbars if content overflows
      padding: "10px",              // Optional: Adds padding for better appearance
      backgroundColor: "#f5f5f5",   // Optional: Background color for code blocks
      borderRadius: "4px",          // Optional: Rounds the corners
      boxSizing: "border-box",      // Includes padding and border in width calculations
    },
    "& code": {
      fontFamily: "monospace",      // Monospaced font for code
      fontSize: "13px",             // Adjust font size as needed
      whiteSpace: "pre-wrap",       // Allows text to wrap
      wordWrap: "break-word",       // Breaks long words
      wordBreak: "break-all",       // Breaks words at arbitrary points
    },
    "& pre code": {
      display: "block",             // Ensures code block takes up full width
    },
	},
	root: {
		fontSize: "calc(var(--active-font-size) - 2px)",
		boxShadow: "none",
		margin: "5px 5px",
		minWidth: "110px",
		padding: "7px",

		"&:hover": {
			background: "white",
			opacity: 0.8,
		},
		"&:disabled": {
			borderColor: "var(--color-light-grey)",
		},
	},
	selected: {
		background: "var(--active-brand-color)",
		color: "white",
		"&:disabled": {
			color: "white",
		},
	},
});

export default function Message({
	uid,
	uidDefinition,
	messageId,
	adminStatus,
	flagged,
	showUser,
	lastMessage,
	user,
	userUid,
	message,
	time,
	options,
	multipleAnswers,
	showDate,
	messagesRef,
	online,
	messageQty,
	handleFlag,
	chatbotIsDraft,
	onImageLoad, // Callback function from ChatPanel
	chatbotId,
	avatarUrl,
	requestCancelled,
	handleDeleteRequest,
	setLoading,
	handleFallbackMessage
}) {
	//convert time to 12 hour:minute am/pm format.
	var hours = time.getHours();
	var minutes = time.getMinutes();
	var ampm = hours >= 12 ? "pm" : "am";
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? "0" + minutes : minutes;
	var date = hours + ":" + minutes + " " + ampm;
	

	  // Add this console log to check the value
		console.log('Avatar URL in Message:', avatarUrl);

	const classes = useStyles({ avatarUrl }); // Pass avatarUrl to useStyles
	const [{ loggingOut }, dispatch] = useStateValue();
	const { instance, accounts } = useMsal();

	useEffect(() => {
		if (lastMessage && uid !== user.uid && messageQty === 1) {
			const tmpRoomId = user.uid + "***" + chatbotId
			firestore
				.collection("rooms")
				.doc(tmpRoomId)
				.set({
					pendingMessages: 0,
					chatbotId: chatbotId,
				}, { merge: true })
				.then(() => {
					firestore
						.collection("rooms")
						.doc(tmpRoomId)
						.collection("messages")
						.doc(messageId)
						.update({
							uid: JSON.parse("null"),
						})
				})
		} else if (
			lastMessage &&
			uid !== user.uid
		) { }
		if (loggingOut) {
			dispatch({
				type: "LOGGING_OUT",
				loggingOut: false,
			});
		}
	}, []);

	const openLinkInNewTab = (url) => {
		window.open(url, '_blank');
	};

	const handleGoToLogin = () => {
		dispatch({
			type: "SET_VIEW",
			view: VIEW.LOGIN,
		});
	};

	const handleLogin = async (loginType) => {
		try {
			if (loginType === "redirect") {
				dispatch({
					type: "SET_VIEW",
					view: VIEW.LOGINTMP,
				});
				if (instance.getAllAccounts().length > 0) {
					const response = await instance.acquireTokenSilent({
						...loginRequest,
						account: accounts[0],
					});
					// console.log("MS logout response", response)
				}
				const loginResult = await instance.loginRedirect(loginRequest);

			}
		} catch (e) {
			console.log(e); // Log the entire error object to understand its structure
			// Use e.errorCode or e.message depending on the structure of your error object
			if (e.errorCode && e.errorCode === 'user_cancelled') {
				console.log("User cancelled the login process.");
				dispatch({
					type: "SET_VIEW",
					view: VIEW.CHATROOM,
				});
				return;
			} else if (e.message && e.message.includes("User cancelled the flow.")) {
				console.log("User cancelled the login process via message.");
				dispatch({
					type: "SET_VIEW",
					view: VIEW.CHATROOM,
				});
				return;
			} else {
				// Handle other errors or rethrow them
				console.error("An unexpected error occurred:", e);
				dispatch({
					type: "SET_VIEW",
					view: VIEW.CHATROOM,
				});
				throw e;
			}
		}
	}
	
	return (
		<>
			{showDate.prevDate && (
				<>
					<Box
						className={classes.date}
						m={0}
						display="flex"
						justifyContent="center"
					>
						{showDate.prevDate}
					</Box>
				</>
			)}
			{/* User Message */}
			{uid === user?.uid + "***" + chatbotId ? (
				<div className={`${classes.message} ${classes.user}`}>
					<div className={classes.messageBody}>
						{Array.isArray(message.data.text) ? message.data.text[0].replace("<span>", "").replace("</span>", "") : message.data.text.replace("<span>", "").replace("</span>", "")}	{" "}
					</div>
					{showUser || lastMessage ? (
						<span className={classes.userName}>
							{user?.displayName ? user?.displayName : "User"} {`• ${date}`}
						</span>
					) : (
						<div className={classes.userPlaceholder} />
					)}
				</div>
			) :
				(uid === "Live Agent") ?
					// {/* Live Agent Message */}
					(
						<>
							<div className={`${classes.message} ${classes.system}`}>
								{showUser || lastMessage ? (
									<div style={{
										backgroundColor: '#37acb8',
										width: '30px',
										height: '30px',
										borderRadius: '50%',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										marginRight: '12px',
										alignSelf: 'flex-end',
										minWidth: '30px',
									}}>
										<img
											className={classes.botLogo}
											src={liveAgentAvatar}
											alt="live agent icon"
											style={{
												alignSelf: 'center', width: '15px', height: '15px',
												marginRight: '0'
											}}
										/>
									</div>
								) : (
									<div className={classes.avatarPlaceholder} />
								)}
								<div 
								className={`${classes.messageBody} ${classes.systemBody}`}
								style={{backgroundColor: `#37acb8`, color: '#FFF'}}
								dangerouslySetInnerHTML={{ __html: message.data.text.replace(/\\"/g, '"') }}
								>
									{/* {message.data.text}{" "} */}
								</div>
							</div>
							{showUser || lastMessage ? (
								<span className={classes.systemName}>
									{/* {"Live Agent"} {`• ${date}`} */}
									{uidDefinition} {`• ${date}`}
								</span>
							) : (
								<div className={classes.avatarPlaceholder} style={{ height: '8px' }} />
							)}
						</>
					)
					:
					(uid === "system" && message.data.text === 'Switching to AI') ?
						// {/* System Message and message is Switching to AI */}
						(
							<Box
								className={classes.date}
								m={0}
								display="flex"
								justifyContent="center"
							>
								Switching to AI
							</Box>
						)
						:
						<>
							{/* Chatbot Message */}
							<div className={`${classes.message} ${classes.system}`}>
								{showUser || lastMessage ? (
									 avatarUrl ? (
										// <div className={classes.botAvatar} />
										<img
										src={avatarUrl}
										alt="chatbot avatar"
										className={classes.botAvatar}
										onError={(e) => {
											console.log('Error loading avatar:', avatarUrl); // Add error logging
											e.target.src = smallRobotAvatar; // Fallback to default avatar if custom one fails
										}}
										onLoad={() => console.log('Avatar loaded successfully')} // Add load logging
									/>
									) : (
										<img
											className={classes.botLogo}
											src={smallRobotAvatar}
											alt="chatbot robot icon"
										/>
									)
								) : (
									<div className={classes.avatarPlaceholder} />
								)}
								<div style={{
									display: 'flex', flexDirection: "column", gap: "15px",
									width: "-webkit-fill-available",
									maxWidth: "fit-content"
								}}>

									{/*If message is greeting or apology*/}
									{
										(message.data.greeting === true || message.data.escalation === true || message.data.questionForm === true) && (message.data.text.replace(/\\"/g, '"') !== "") &&
										(
											<div className={`${classes.messageBody} ${classes.systemBody} ${classes.systemBodyWidth}`} >
												<div className={`${classes.richeTextWrapper}`} dangerouslySetInnerHTML={{ __html: message.data.text.replace(/\\"/g, '"') }}
													onClick={(e) => {
														if (e.target.tagName === 'A') {
															e.preventDefault();
															openLinkInNewTab(e.target.href);
														}
													}}
												/>
												{/* Add hyperlink if it exists */}
												{message.data.link &&
													(
														<>
															<a
																className={classes.link}
																href={message.data.link}
																target="_blank"
																rel="noreferrer"
															>
																here
															</a>
															<span>.</span>
														</>
													)}
												{/* Show feedback button when admin and toggled on */}
												{/* {adminStatus.adminUser &&
										adminStatus.feedbackOn && (
											<FeedbackButton
												flagged={flagged}
												userId={user.id}
												messageId={messageId}
												handleFlag={handleFlag}
											/>
										)} */}
											</div>
										)
									}
									{/*If message is form*/}
									{
										(message.data.form === true) &&
										(
											<FormUserData data={message.data} chatbotId={chatbotId} />
										)
									}
									{/*If message is isFallbackFEmessage (fallback message genereted after few second if not answer from backend to cover scenario where the back end logic get stack or error)*/}
									{
										(message.data.isFallbackFEmessage === true) &&
										(
											<div className={`${classes.messageBody} ${classes.systemBody}`}>
												{Array.isArray(message.data.text) ? message.data.text[0].replace("<span>", "").replace("</span>", "") : message.data.text.replace("<span>", "").replace("</span>", "")}	{" "}
												{/* Show delete request and then resubmit button when admin and toggled on */}
												{true && (
														<CancelRequestButton
															requestCancelled={message.data.requestCancelled}
															flagged={requestCancelled}
															chatbotId={chatbotId} 
															uid={uid}
															user={user}
															messageId={messageId}
															handleFlag={handleDeleteRequest}
															disabled={message.data.cancelRequestBtnDisabled || message.data.requestCancelled}
															questionRef={message.data.questionRef}
														/>
													)}
													{requestCancelled && (
														<ResubmitRequestButton
															flagged={requestCancelled}
															// userId={user?.id}
															messageId={messageId}
															handleFlag={handleDeleteRequest}
															chatbotId={chatbotId}
															user={user}
															questionRef={message.data.questionRef}
															setLoading={setLoading}
															disabled={message.data.resubmitRequestBtnDisabled}
															handleFallbackMessage={handleFallbackMessage}
														/>
													)}
											</div>
										)
									}
									{/*If message is NOT greeting and NOT a card -> regular message*/}
									{(!message.data.isFallbackFEmessage && message.data.greeting === undefined && message.data.escalation === undefined && (message.data.questionForm === undefined || message.data.questionForm === false) && !message.data.linkIkea && !message.data.linkStore && !message.data.form && message.data.text !== 'Switching to AI') &&
										(
											<div className={`${classes.messageBody} ${classes.systemBody}`}>
												{Array.isArray(message.data.text) ? message.data.text[0].replace("<span>", "").replace("</span>", "") : message.data.text.replace("<span>", "").replace("</span>", "")}	{" "}
												{/* Add hyperlink if it exists */}
												{message.data.link &&
													(
														<>
															<a
																className={classes.link}
																href={message.data.link}
																target="_blank"
																rel="noreferrer"
															>
																here
															</a>
															<span>.</span>
														</>
													)}
												{/* Show feedback button when admin and toggled on */}
												{/* Themporarly commented because this implementation is only for intents */}
												{/* {adminStatus.adminUser &&
													adminStatus.feedbackOn && (
														<FeedbackButton
															flagged={flagged}
															userId={user?.id}
															messageId={messageId}
															handleFlag={handleFlag}
														/>
													)} */}
											</div>
										)
									}
									{/*If message is NOT greeting and has link and has NOT map: and not gmaps -> CARD message*/}
									{/* Render Card */}
									{/* Show feedback button when admin and toggled on */}
									{(!message.data?.greeting && (message.data.linkIkea || message.data.linkStore)) &&
										(
											<div className={`${classes.messageBodyCard} ${classes.systemBodyCard}`} style={{
												width: "calc(75% + 20px)", maxWidth: "600px",
												background: "none",
												border: "none",
												boxShadow: "none",
											}}>

												{/* <Card */}
												{message.data.metatag[0].image.toLowerCase().endsWith(".pdf") ?
													<PdfCard
														text={message.data.text}
														href={message.data.linkIkea || message.data.linkStore}
														metatag={message.data.metatag}
														isIkea={message.data.linkIkea}
														isStore={message.data.linkStore}
														linkMap={message.data.linkMap}
														isLast={lastMessage}
														onImageLoad={onImageLoad}
													/>
													:
													<CustomCard
														text={message.data.text}
														href={message.data.linkIkea || message.data.linkStore}
														metatag={message.data.metatag}
														isIkea={message.data.linkIkea}
														isStore={message.data.linkStore}
														linkMap={message.data.linkMap}
														isLast={lastMessage}
														onImageLoad={onImageLoad}
													/>
												}
												{/* Themporarly commented because this implementation is only for intents */}
												{/* {adminStatus.adminUser &&
													adminStatus.feedbackOn && (
														<FeedbackButton
															flagged={flagged}
															userId={user?.id}
															messageId={messageId}
															handleFlag={handleFlag}
														/>
													)} */}
											</div>
										)
									}
								</div>
									{/******************************************************************************
									ORIGINAL
												<div
													className={`${classes.messageBody} ${classes.systemBody}`}
												>
													{message.data?.greeting ? <div dangerouslySetInnerHTML={{ __html: message.data.text }} /> : message.data.text}
													{message.data.link && (
														<>
															<a
																className={classes.link}
																href={message.data.link}
																target="_blank"
																rel="noreferrer"
															>
																here
															</a>
															<span>.</span>
														</>
													)}
													{adminStatus.adminUser &&
														adminStatus.feedbackOn && (
															<FeedbackButton
																flagged={flagged}
																userId={user.id}
																messageId={messageId}
																handleFlag={handleFlag}
															/>
														)}
												</div>
											
										END ORIGINAL */}
							</div>
							{/* Multiple form answer Buttons*/}
							{multipleAnswers && lastMessage && (
								<AnswerBtnGroup
									messagesRef={messagesRef}
									userId={user?.id}
									messageId={messageId}
									multipleAnswers={multipleAnswers}
									online={online}
									linkMap={message.data.linkMap}
									chatbotIsDraft={chatbotIsDraft}
									switchOptions={message.data.text === "Which agent would you like me to direct you to?" ? true : false}
									chatbotId={chatbotId}
								/>
							)}
							{/* Multiple Response Buttons*/}
							{options && lastMessage && (
								<ResponseBtnGroup
									messagesRef={messagesRef}
									userId={user?.id}
									messageId={messageId}
									options={options}
									online={online}
									linkMap={message.data.linkMap}
									chatbotIsDraft={chatbotIsDraft}
									switchOptions={message.data.text === "Which agent would you like me to direct you to?" ? true : false}
									chatbotId={chatbotId}
								/>
							)}
							{/*If message is not autorized in Sofina*/}
							{
								(message.data.loginBtn === true && lastMessage) &&
								(
									<Button
									// disabled={!online || selected ? true : false || chatbotIsDraft}
									// onClick={() => handleGoToLogin()}
									onClick={() => handleLogin("redirect")}
									className={classes.root}
									style={{display: "block", marginLeft: "50px"}}
								>
									Go to login
								</Button>
								)
							}
							{/* System Message Subtitle */}
							{showUser || lastMessage ? (
								<span className={classes.systemName}>
									{messageQty === 1 ?
										(message.data.chatbotName ? message.data.chatbotName : (""))
										: (message.data.chatbotName ? message.data.chatbotName : (""))} • {date}
								</span>
							) : (
								<div className={classes.userPlaceholder} />
							)}
						</>
			}
			{showDate.nextDate && (
				<>
					<Box
						className={classes.date}
						m={0}
						display="flex"
						justifyContent="center"
					>
						{showDate.nextDate}
					</Box>
				</>
			)}
		</>
	);
}
