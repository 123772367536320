import { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from '@material-ui/core/TextField';
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";
import firebase from "firebase/app";
import DOMPurify from "dompurify";
import { useAuthState } from "react-firebase-hooks/auth";
import { firestore, auth } from "../services/firebase/firebase";

const useStyles = makeStyles({
	messageInput: {
		margin: "0 18px",
		fontSize: "16px",
		marginTop: "10px",
		width: "calc(100% - 36px)",

		"& div:first-child": {
			borderRadius: "20px",
			padding: "6px 12px 7px 12px",
			minHeight: "40px",
			borderWidth: "1px",
			border: "solid 1px var(--color-light-grey)",
			"&.Mui-focused ": {
				borderColor: "var(--active-brand-color)",
			},
			"&:focus-within": {
				borderColor: "var(--active-brand-color)",
			},
		},

		"& textarea": {
			overflow: "auto !important", //used with input but now with text area not required because it create a small issue to go to new line before the end of the line
			maxHeight: "115px", //"76px",
		},

		"& fieldset": {
			display: "none"
		}
	},
	icon: {
		fill: "var(--active-brand-color)",
	},
	iconDisabled: {
		fill: "var(--color-light-grey)",
	},
	inputButton: {
		height: "30px",
		width: "35px",
		borderRadius: "6px",
		margin: 0,
		"&:focus": {
			border: "1px var(--active-brand-color) solid",
		},
	},
	"@media (max-width: 325px)": {
		messageInput: {
			margin: "0 10px",
		},
	},
	hiddenInput: {
		fontSize: "var(--active-font-size)",
		border: "none",
		outline: "none",
		"&:hover": {
			border: "none",
			outline: "none",
		},
		"&:focus": {
			border: "none",
			outline: "none",
		},
		"&:focus-within": {
			border: "none",
			outline: "none",
		},
		"&:focus-visible": {
			border: "none",
			outline: "none",
		},
		"&:active": {
			border: "none",
			outline: "none",
		},
		"&:target": {
			border: "none",
			outline: "none",
		},
		"&:visited": {
			border: "none",
			outline: "none",
		},
	}
});

const badWordsArr = ['bonk', 'shag', 'slapper', 'tart', 'cunt', 'fuck', 'motherfucker', 'twat', 'snatch', 'pussy', 'punani', 'prick', 'minge', 'knob', 'gash', 'flaps', 'dickhead', 'dick', 'cock', 'clunge', 'bloodclaat', 'bellend', 'bastard', 'tits', 'bitch', 'shit', 'pissed', 'munter', 'feck', 'bullshit', 'bollocks', 'bint', 'arsehole', 'asshole', 'ass', 'cockfoam', 'nigger', 'damn']

function hashingBadWords(str) {
	const myArray = str.split(" ");
	for (let i = 0; i < myArray.length; i++) {
		if (badWordsArr.includes(myArray[i].toLowerCase())) {
			myArray[i] = myArray[i].substring(0, 1) + myArray[i].substring(1, [myArray[i].length - 1]).replace(/[a-zA-Z]/gi, '*') + myArray[i].substring([myArray[i].length - 1]);
		}
	}
	return myArray.join(' ');
}

export default function MessageInput({
	messagesRef,
	online,
	setErrMsg,
	setShowErrorMessage,
	disableBtn,
	messages,
	justFlag,
	firstLoading,
	handleSubmit,
	justOpen,
	handleJustOpen,
	setLoading,
	chatbotIsDraft,
	chatbotId,
	handleFallbackMessage
}) {

	const refMessageInput = useRef(null);
	const [authState] = useAuthState(auth);
	const [messageValue, setMessageValue] = useState("");
	const [messageSubmitting, setMessageSubmitting] = useState(false);
	const classes = useStyles();

	const handleMessageSubmit = async (e) => {
		e.preventDefault();
		/*Sanitize and validate input from user*/
		let sanitizedInput = DOMPurify.sanitize(messageValue.replace("<", " < ").replace(">", " > "));
		setMessageValue("");
		sanitizedInput = sanitizedInput.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
		const censuredSanitizedInput = hashingBadWords(sanitizedInput);
		setMessageValue(censuredSanitizedInput);
		sessionStorage.setItem(
			"prevMessage",
			`${censuredSanitizedInput}`
		);
		setMessageSubmitting(true);
		setLoading(true) //to show the loader immediatly after the user submit the message
		const options = {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
			hour12: false, // 24-hour format
			timeZoneName: 'short',
			fractionalSecondDigits: 3 // Milliseconds
		};
		const formattedDate = new Date(Date.now()).toLocaleString('en-US', options);
		console.log("ADD new message to firestore call", formattedDate);
		const snapRoom = await firestore
			.collection("rooms")
			.doc(authState?.uid + "***" + chatbotId)
			.get();

		const roomDdata = snapRoom.data();
		const key = roomDdata.key;
		const appKey = roomDdata.appKey;
		const dataToAdd = {
			text: [censuredSanitizedInput.trim()],
			createdAt: firebase.firestore.FieldValue.serverTimestamp(),
			uid: authState?.uid + "***" + chatbotId, //userUid,
			photoUrl:
				"https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/batman_hero_avatar_comics-512.png",
			localDateTime: formattedDate,
			isTimeKeeper: false
		};

		if (key !== undefined) {
			dataToAdd.key = key;
		}

		if (appKey !== undefined) {
			dataToAdd.appKey = appKey;
		}

		await messagesRef
			.add(dataToAdd)
			.then((res) => {
				console.log("handleMessageSubmit.then")
				const messageId = res.id; // Extract the message ID
				console.log("New message ID:", messageId); // Log the message ID
				const formattedDate = new Date(Date.now()).toLocaleString('en-US', options);
				console.log("ADD new message to firestore feedback", formattedDate)
				setMessageValue("");
				sessionStorage.setItem("prevMessage", "");
				setMessageSubmitting(false);
				handleFallbackMessage(messageId);
				return;
			})
			.catch((err) => {
				console.error("ERR from catch messageInput add", err);
				setMessageSubmitting(false);
				setMessageValue(sessionStorage.getItem("prevMessage"));
			});
	};

	useEffect(() => {
		// Check if a prevMessage is present in session to take it when loading the chatbot
		const prevMessage = sessionStorage.getItem("prevMessage");
		const submitMsg = sessionStorage.getItem("submitMsg") === "true";
		console.log("submitMsg", submitMsg)
		if (prevMessage && submitMsg) sessionStorage.setItem("prevMessage", "");
		if (prevMessage && !submitMsg) setMessageValue(prevMessage);
		sessionStorage.setItem("submitMsg", "false");
		console.log("useEffect")
		const errMsg = sessionStorage.getItem("errMsg");
		if (errMsg === "The message exceeds characters limit (256)") {
			setErrMsg({
				message:
					"The message exceeds characters limit (256)",
				location: "msgInput",
			});
			setShowErrorMessage(true);
		} else if (errMsg === "Invalid message") {
			setErrMsg({
				message: "Invalid message",
				location: "msgInput",
			});
			setShowErrorMessage(true);
		}
	}, []);

	const handleFormSubmit = (e) => {
		const options = {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
			hour12: false, // 24-hour format
			timeZoneName: 'short',
			fractionalSecondDigits: 3 // Milliseconds
		};
		const formattedDate = new Date(Date.now()).toLocaleString('en-US', options);
		console.log("FormSubmit", formattedDate)
		sessionStorage.setItem("submitMsg", "true");
		console.log("handleFormSubmit", handleFormSubmit)
		e.preventDefault();
		handleMessageSubmit(e);
		refMessageInput.current.focus();
		handleSubmit();
		handleJustOpen();
	};

	return (
		<div style={{ position: "relative", bottom: "0px", marginLeft: "8px", }}>
			<form onSubmit={handleFormSubmit}>
				{/* <Input */}
				<TextField
					id="text-field-multiline"
					multiline
					maxrows={6}
					className={classes.messageInput}
					aria-label="chatbot message input"
					variant="outlined"
					disabled={messageSubmitting || !online || chatbotIsDraft}
					placeholder="Ask a question"
					value={messageSubmitting ? "" : messageValue}
					onChange={(e) => {
						let userMsg = e.target.value;
						/*Sanitize and validate input from user*/
						let sanitizedUserMsg = DOMPurify.sanitize(userMsg)
						if (userMsg.length > 256) {
							setErrMsg({
								message:
									"The message exceeds characters limit (256)",
								location: "msgInput",
							});
							setShowErrorMessage(true);
							//To save the message if the chatbot is minimized
							sessionStorage.setItem(
								"errMsg",
								"The message exceeds characters limit (256)"
							);
						} else if (userMsg && !sanitizedUserMsg) {
							setErrMsg({
								message: "Invalid message",
								location: "msgInput",
							});
							setShowErrorMessage(true);
							//To save the message if the chatbot is minimized
							sessionStorage.setItem(
								"errMsg",
								"Invalid message"
							);
						} else {
							setErrMsg({
								message: "",
								location: "",
							});
							setShowErrorMessage(false);
							//To clear the message
							sessionStorage.removeItem("errMsg");
						}
						setMessageValue(userMsg);
						//To save the message if the chatbot is minimized
						sessionStorage.setItem(
							"prevMessage",
							`${userMsg}`
						);
					}}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							if (!messageValue.replace(/\s/g, "").length ||
								!online ||
								messageSubmitting ||
								disableBtn || messageValue.length > 256 || sessionStorage.getItem("errMsg")) {
								console.log("Invalid message")
							} else {
								console.log('onKeyDown else');
								handleFormSubmit(e)
							}
						}
					}}
					inputRef={(input) => input && !justFlag && !justOpen && input.focus()}
					InputProps={{
						endAdornment:
							<InputAdornment position="end">
								<IconButton
									type="submit"
									className={classes.inputButton}
									disabled={
										!messageValue.replace(/\s/g, "").length ||
										!online ||
										messageSubmitting ||
										disableBtn || messageValue.length > 256 || sessionStorage.getItem("errMsg")
									}
									aria-label="send message"
									edge="end"
								>
									<SendIcon
										className={
											messageValue.replace(/\s/g, "")
												.length &&
												online &&
												!messageSubmitting &&
												!disableBtn
												? classes.icon
												: classes.iconDisabled
										}
									/>
								</IconButton>
							</InputAdornment>,
						"aria-label": "chatbot message input",
					}}
				/>
				{/* ></Input> */}
			</form>
			{/* Add to get the focus when a new message is submitted and keep the softkeyboard of the phone open to avoid it flickering because close and open quikly
			This modification does not affect the app when running on PC so can be implemented in all condition */}
			{/* <input type="number" tabIndex="-1" ref={refMessageInput} style={{ */}
			<input type="text" tabIndex="-1" ref={refMessageInput} style={{
				position: 'absolute',
				right: '0%',
				top: '40px',
				width: '0%',
				height: '0px',
				margin: "auto",
				zIndex: "-1",
				fontSize: "16px",
			}}
				className={classes.hiddenInput}
			/>
		</div>
	);
}
