import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Box,
  Tooltip,
  Button,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@material-ui/core";
import { useStateValue } from "../context/state/provider";
import UserModal from "../components/UserModal";
import HeaderTitle from "../components/HeaderTitle";
import TextIcon from "../images/textIcon.svg";
import RefreshIcon from "../images/refreshIcon.svg";
import UserIcon from "../images/userIcon.svg";
import { VIEW } from "../constants/view.constants";
import { auth } from "../services/firebase/firebase";
import writeGreetingsOptions from "../helpers/writeGreetingsOptions";
import writeForm from "../helpers/writeForm"
import { useAuthState } from "react-firebase-hooks/auth";

const useStyles = makeStyles({
	appbar: {
		width: "100%",
		color: "var(--custom-client-text)",
		height: "75px",
		padding: "0px 15px",
		borderRadius: "6px 6px 0 0",
		transition: "background-color 0.3s ease-in",
	},
	'@media (max-height: 180px)': {
		appbar: {
			display: "none",
		}
	},
	icon: {
		color: "#ffffff",
		height: "28px",
		width: "28px",
	},
	toolbar: {
		padding: 0,
	},
	iconButton: {
		padding: "10px",
		alignItems: 'center',
		justifyContent: 'center',
		flex: '0 0 auto',
		overflow: 'visible',
		fontSize: '1.5rem',
		textAlign: 'center',
		transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		color: 'inherit',
		borderRadius: '50%',
		border: '0',
		cursor: 'pointer',
		margin: '0',
		display: 'inline-flex',
		outline: '0',
		position: 'relative',
		userSelect: 'none',
		textDecoration: 'none',
		backgroundColor: 'transparent',
		'-webkit-appearance': 'none',
		'-webkit-tap-highlight-color': 'transparent',

		"&:hover": {
			background: "transparent",
			opacity: "0.8",
		},
		"&:disabled": {
			background: "transparent",
			opacity: "0.8",

			color: 'rgba(0, 0, 0, 0.26)',
			cursor: 'default',
			'pointer-events': 'none',
		},
		"&:focus": {
			background: "rgba(0,0,0,0.2)",
		},
	},
	iconButtonDisabled: {
		padding: "10px",
		alignItems: 'center',
		justifyContent: 'center',
		flex: '0 0 auto',
		overflow: 'visible',
		fontSize: '1.5rem',
		textAlign: 'center',
		transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		borderRadius: '50%',
		border: '0',
		margin: '0',
		display: 'inline-flex',
		outline: '0',
		position: 'relative',
		userSelect: 'none',
		textDecoration: 'none',
		backgroundColor: 'transparent',
		'-webkit-appearance': 'none',
		'-webkit-tap-highlight-color': 'transparent',
		background: "transparent",
		opacity: "0.8",
		color: 'rgba(0, 0, 0, 0.26)',
		cursor: 'default',
		'pointer-events': 'none',

		"&:hover": {
			background: "transparent",
			opacity: "0.8",
		},
		"&:disabled": {
			background: "transparent",
			opacity: "0.8",

			color: 'rgba(0, 0, 0, 0.26)',
			cursor: 'default',
			'pointer-events': 'none',
		},
		"&:focus": {
			background: "rgba(0,0,0,0.2)",
		},
	},
	"@media (max-width: 300px)": {
		icon: {
			color: "#ffffff",
			height: "25px",
			width: "25px",
		},
		iconButton: {
			margin: "8px",
			padding: "0",
		},
	},
});

function extractTokenFromUrl() {
	// Get the URL query parameters
	const queryString = window.location.search;

	// Parse the query string into an object
	const queryParams = new URLSearchParams(queryString);

	// Get the value of the 'token' parameter
	const token = queryParams.get('token');
	console.log(token);
	return token;
}

export default function Navbar({ user, brandingTitle, subtitle, avatarUrl, firestore, chatbotId }) {
	const classes = useStyles();
	const [{ view }, dispatch] = useStateValue(); // user,
	const [modalOpen, setModalOpen] = useState(false);
	const [authState, authLoading, authError] = useAuthState(auth);

	const handleAccessibilityButton = () => {
		dispatch({
			type: "SET_VIEW",
			view: VIEW.ACCESSIBILITY,
		});
	};

	const handleClickOpen = () => {
		setModalOpen(true);
	};

	const handleClose = (value) => {
		if (view === VIEW.CHATROOM) {
		}
		setModalOpen(false);
	};

	const handleLogout = () => {
		console.log("handleLogout")
		//Set logginOut state to hide input field and error message if any while the logout and fetch of new greeting message is completed
		dispatch({
			type: "LOGGING_OUT",
			loggingOut: true,
		});

		sessionStorage.setItem("prevProvider", JSON.parse(sessionStorage.getItem("CLIENT")).defaultAgent);
		auth.signOut()
			.then(() => {
				console.log("Signout Successful");

				dispatch({
					type: "ADMIN_SETTINGS",
					admin: {
						adminUser: false,
						feedbackOn: false,
					},
				});
				localStorage.removeItem("userType");

				//Before closing the modal login as anonymous user
				auth.signInAnonymously()
					.then((currentUser) => {
						console.log({ currentUser });
						const snapShot = async () => {
							await firestore
								.collection("rooms")
								.doc(currentUser.user.uid + "***" + chatbotId)
								.get()
						}

						if (!snapShot.exists) {
							firestore
								.collection("rooms")
								.doc(currentUser.user.uid)
								.set({
									chatbotId: chatbotId,
									provider: JSON.parse(sessionStorage?.getItem("CLIENT"))?.defaultAgent,
									providerId: JSON.parse(sessionStorage?.getItem("CLIENT"))?.defaultAgentId,
								}, { merge: true })
								.then(() => {
									//get chatbot info to check in the form need to be display immediatly
									firestore
										.collection("chatbots")
										.doc(chatbotId)
										.get()
										.then(async (res) => {
											const chatbotDocVal = res.data()
											console.log("GET CHATBOT INFO", chatbotDocVal);
											//If the trigger for form is first request
											if (chatbotDocVal.trigger === "After First Request") {
												writeForm(currentUser.user.uid, chatbotId)
											} else {
												writeGreetingsOptions(currentUser.user.uid, chatbotId)
											}
										})
										.catch((err) => {
											console.log("ERROR", err);
										});
								})
								.catch((error) => {
									console.error("Error writing document: ", error);
								});
						}
						//check userType in local storage
						localStorage.removeItem("userType");

						dispatch({
							type: "USER",
							user: {
								...user,
								id: currentUser.user.uid,
								loggedIn: false,
								name: null,
							},
						});

						dispatch({
							type: "SET_VIEW",
							view: VIEW.CHATROOM,
						});
					})
					.catch((err) => {
						console.log("ERROR", err);
					});
			})
			.catch((err) => {
				console.error("signout error: ", err);
			});
	};

	const handleRefreshRoom = async () => {
		try {
			console.log("Starting handleRefreshRoom...");
			const loggedInUid = auth.currentUser.uid;
			const dateRefresh = new Date().toISOString().replace(/[:.]/g, '-'); // Format date safely for Firestore path
			console.log("dateRefresh:", dateRefresh);
			console.log("loggedInUid:", loggedInUid);
			console.log("chatbotId:", chatbotId);
			const roomRef = firestore.collection("rooms").doc(loggedInUid + "***" + chatbotId);

			// Get existing messages
			const oldMessagesSnapshot = await roomRef.collection("messages").get();

			if (oldMessagesSnapshot.empty) {
				console.log("No messages to move.");
				return;
			}

			console.log(`Found ${oldMessagesSnapshot.size} messages to copy and delete.`);

			// Prepare batch operations
			const batch = firestore.batch();

			// Copy messages to new collection and delete from old collection
			oldMessagesSnapshot.forEach((doc) => {
				const newDocRef = roomRef.collection(`messages-${dateRefresh}`).doc(doc.id);
				batch.set(newDocRef, doc.data()); // Copy the document to the new collection

				const oldDocRef = roomRef.collection("messages").doc(doc.id);
				batch.delete(oldDocRef); // Delete the document from the old collection
			});

			// Commit batch operations
			await batch.commit();
			console.log("Batch commit complete: Documents copied and deleted.");

			// Proceed with the rest of the code
			const chatbotDoc = await firestore.collection("chatbots").doc(chatbotId).get();
			const chatbotDocVal = chatbotDoc.data();
			console.log("GET CHATBOT INFO", chatbotDocVal);

			// Call appropriate function based on trigger
			if (chatbotDocVal.trigger === "After First Request") {
				await writeForm(loggedInUid, chatbotId);
			} else {
				await writeGreetingsOptions(loggedInUid, chatbotId);
			}

			// Update pendingMessages
			await roomRef.set({ pendingMessages: 0 }, { merge: true });
			console.log("Pending messages set to 0.");

			// Dispatch action to set view
			dispatch({
				type: "SET_VIEW",
				view: VIEW.CHATROOM,
			});
		} catch (error) {
			console.error("Error in handleRefreshRoom:", error);
		}
	};

	const token = extractTokenFromUrl();

	return (
		<div>
			<AppBar className={classes.appbar}>
				<Toolbar className={classes.toolbar}>
					<div>
						<HeaderTitle brandingTitle={brandingTitle} subtitle={subtitle}  avatarUrl={avatarUrl} />
					</div>
					<div>
						<Box display="flex">
							{/* Refresh room button */}
							<Tooltip title="refresh room">
								<div
									tabIndex={0}
									className={authState?.loggedIn ? classes.iconButtonDisabled : classes.iconButton}
									disabled={authState?.loggedIn}
									aria-label="refresh room"
									color="inherit"
									autoFocus={false}
									onClick={(e) => {
										handleRefreshRoom()
										document.activeElement.blur();
									}}
								>
									<img
										src={RefreshIcon}
										className={classes.icon}
										alt="refresh icon"
										aria-hidden="true"
									/>
								</div>
							</Tooltip>
							{/* Accessibility options button */}
							<Tooltip title="accessibility options">
								<div
									tabIndex={0}
									className={view === VIEW.ACCESSIBILITY ? classes.iconButtonDisabled : classes.iconButton}
									disabled={
										view === VIEW.ACCESSIBILITY
											? true
											: false
									}
									aria-label="accessibility options"
									color="inherit"
									autoFocus={false}
									onClick={(e) => {
										if (view !== VIEW.ACCESSIBILITY)
											handleAccessibilityButton()
										document.activeElement.blur();
									}}
								>
									<img
										src={TextIcon}
										className={classes.icon}
										alt="text icon"
										aria-hidden="true"
									/>
								</div>
							</Tooltip>
							{/* Login button */}
							{/* Does not show login button if token is present*/}
							{!token && <Tooltip title={authState ? authState.isAnonymous ? "login" : "logout" : "login"}>
								<div
									tabIndex={0}
									className={token || modalOpen || view === VIEW.LOGIN ? classes.iconButtonDisabled : classes.iconButton}
									disabled={token || modalOpen || view === VIEW.LOGIN ? true : false}
									onClick={() => {
										handleClickOpen()
										document.activeElement.blur();
									}}
									aria-label="login"
									color="inherit"
									autoFocus={false}
								>
									<img
										src={UserIcon}
										className={classes.icon}
										alt="user icon"
										aria-hidden="true"
									/>
								</div>
							</Tooltip>
}
						</Box>
					</div>
				</Toolbar>
			</AppBar>
			<UserModal
				firestore={firestore}
				setModalOpen={setModalOpen}
				open={modalOpen}
				onClose={handleClose}
				userDB={user}
				chatbotId={chatbotId}
			/>
		</div>
	);
}
