import { useEffect, useRef, useState, forwardRef } from "react";
import { useStateValue } from "../context/state/provider";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Message from "../components/Message";
import MessageLoader from "../components/MessageLoader";
import MessageInput from "../components/MessageInput";
import Loader from "react-loader-spinner";
import ErrorIcon from "@material-ui/icons/Error";
import { useDocumentData } from "react-firebase-hooks/firestore";

const useStyles = makeStyles({
	chatDisplay: {
		overflowY: "auto",
		overflowX: "hidden",
		animation: "fadeInAnimation 0.5s ease",
		maxHeight: "calc(100% - 59px)",
		paddingRight: "8px"
	},
	loadingContainer: {
		marginTop: "100px",
		color: "var(--color-dark-grey)",
		fontSize: "18px",
		animation: "fadeInAnimation 0.1s linear",
	},
	errorBox: {
		borderRadius: "10px",
		padding: "15px",
		backgroundColor: "var(--bot-msg-bg)",
		marginLeft: "42px",
	},
	"@media (max-width: 375px)": {
		chatDisplay: {
			padding: "8px 8px 0px 8px",
		},
	},
});

const ChatPanel = forwardRef(({
	messages,
	messagesRef,
	firestore,
	fsLoading,
	fsError,
	online,
	userLoading,
	disableBtn,
	setErrMsg,
	setShowErrorMessage,
	justOpen,
	handleJustOpen,
	chatbotIsDraft,
	user,
	userLoadingAU,
	chatbotId,
	avatarUrl,
	handleFallbackMessage
}, ref) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [{ loaded, admin, loggingOut }, dispatch] = useStateValue(); //user
	const messagesEndRef = useRef(null);
	const chatPanelRef = useRef(null);
	const [submittingMsg, setSubmittingMsg] = useState(false);
	const [justFlag, setJustFlag] = useState(false)

	function handleFlag() {
		setJustFlag(true)
	}

	function handleSubmit() {
		setSubmittingMsg(true)
		setJustFlag(false)
	}

	// toggle on chatPanel render to force scroll when opening.
	useEffect(() => {
		dispatch({
			type: "CHAT_PANEL_STATE",
			loaded: true,
		});

		return () => {
			dispatch({
				type: "CHAT_PANEL_STATE",
				loaded: false,
			});
		};
	}, [dispatch]);

	const roomRef =
		!userLoadingAU && !fsError && user
			? firestore.collection("rooms").doc(user.uid + "***" + chatbotId)
			: "";

	const [roomValue] = useDocumentData(roomRef);
	// Manage message loader on pendingMessages change
	useEffect(() => {
		roomValue && roomValue.pendingMessages > 0
			? setLoading(true)
			: setLoading(false);
	}, [roomValue]);

	//to reset loader after the network disconnect
	useEffect(() => {
		if (!online) {
			setLoading(false);
		}
	}, [online]);

	useEffect(() => {
		// if (!justFlag) {
			if (!justFlag && messagesEndRef.current) {
				// messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
				// messagesEndRef.current.scrollIntoView(); //commented for test
			}
			else if (!justFlag && !loading) {
				// messagesEndRef.current?.scrollIntoView('unset'); //commented for test
				// messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', alignToTop: false, block: "center" }); //add for test
				setSubmittingMsg(false)
			}
			// else if (!justFlag && lastMsgPaint) {
			// 	messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
			// 	setLastMsgPaint(false)
			// }
		// } else {
		// }
	}, [loading, loaded, roomRef, justFlag, 
		// lastMsgPaint
	]);


	//********************************************
	const scrollToBottom = () => {
    chatPanelRef.current.scrollTop = chatPanelRef.current.scrollHeight;
  };

  const handleImageLoad = () => {
    if (!justFlag && !loading) {
      scrollToBottom(); // Scroll to bottom only if not flagged and not loading
    }
    setSubmittingMsg(false);
  };
	//********************************************

	useEffect(() => {
		// if (!justFlag) {
			if (messages) {
				// messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
				// messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', alignToTop: false, block: "center" }); //commented for test
			}

			if(chatPanelRef.current){
				chatPanelRef.current.scrollTop =
				chatPanelRef.current.scrollHeight;
			}
			// else if (!justFlag && !loading) {
			// 	// messagesEndRef.current?.scrollIntoView('unset'); //commented for test
			// 	messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' }); //add for test
			// 	setSubmittingMsg(false)
			// }
			// else if (!justFlag && lastMsgPaint) {
			// 	messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
			// 	setLastMsgPaint(false)
			// }
		// } else {
		// }
	}, [messages, loading
	]);

	const showAvatar = (currentUid, currentIndex) => {
		const nextMsg = messages[currentIndex + 1];
		if (!nextMsg || nextMsg.data.uid === currentUid) {
			return false;
		}
		return true;
	};

	const showDate = (time, currentIndex) => {
		const nextMsg = messages[currentIndex + 1] || null;
		const currentMsgDay = time.toDate().toDateString();
		const nextMsgDay = nextMsg
			? nextMsg.data.createdAt.toDate().toDateString()
			: null;

		const dates = {};
		const today = new Date();

		if (nextMsgDay && currentMsgDay !== nextMsgDay) {
			//add next message date
			dates.nextDate = nextMsgDay;
		}
		if (currentIndex === 0 && currentMsgDay !== today.toDateString()) {
			// return prev and previous date
			dates.prevDate = currentMsgDay;
		}
		return dates;
	};

	return (
		<>
			<div className={classes.chatDisplay} ref={chatPanelRef}>
				{!userLoading &&
					messages &&
					messages.length &&
					!fsLoading &&
					!fsError ? (
					<>
						{messages.map((msg, i) => (
							<Message
								handleFlag={handleFlag}
								key={msg.id}
								messageId={msg.id}
								adminStatus={admin}
								flagged={msg.data.adminFlag}
								lastMessage={messages.length === i + 1}
								showUser={showAvatar(msg.data.uid, i)}
								showDate={showDate(msg.data.createdAt, i)}
								options={msg.data.options}
								multipleAnswers={msg.data.multipleAnswers}
								user={user}
								uid={msg.data.uid}
								uidDefinition={msg.data.uidDefinition}
								avatar={msg.data.photoUrl}
								message={msg}
								time={msg.data.createdAt.toDate()}
								messagesRef={messagesRef}
								online={online}
								messageQty={messages.length}
								chatbotIsDraft={chatbotIsDraft}
								onImageLoad={handleImageLoad} // Pass callback to Message
								chatbotId={chatbotId}
								requestCancelled={msg.data.requestCancelled}
								setLoading={setLoading}
								handleFallbackMessage={handleFallbackMessage}
								avatarUrl={avatarUrl}
							/>
						))}
						{loading && online && <MessageLoader />}
						{!online && (
							<Box m={2} className={classes.errorBox}>
								<Box>
									<ErrorIcon color="error" />
								</Box>
								<Typography>
									Network Error.
									<br />
									Connection required to send and receive
									messages.
								</Typography>
							</Box>
						)}
						{chatbotIsDraft && (
							<Box m={2} className={classes.errorBox}>
								<Box>
									<ErrorIcon color="error" />
								</Box>
								<Typography>
									Draft mode.
									<br />
									Sorry the chatbot is in draft mode.
								</Typography>
							</Box>
						)}
						{<div ref={messagesEndRef} />}
					</>
				) : (
					<>
						{(!fsError || userLoading) && (
							<div className={classes.loadingContainer}>
								Loading Messages
								<br />
								<br />
								<Loader
									type="Oval"
									color="var(--active-brand-color)"
									height={120}
									width={75}
								></Loader>
							</div>
						)}
						{fsError && !userLoading && (
							<Box
								className={classes.errorBox}
								m={7}
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								<Typography variant="h7">
									<ErrorIcon color="error" />
									<br />
									An error occured. <br />
									<br /> Please try refreshing the page.{" "}
									<br />
								</Typography>
							</Box>
						)}{" "}
					</>
				)}
			</div>
			<div style={{ display: "flex", flex: "1 1 auto" }}></div>
			{!loggingOut && <MessageInput
				online={online}
				messagesRef={messagesRef}
				firestore={firestore}
				setErrMsg={setErrMsg}
				setShowErrorMessage={setShowErrorMessage}
				disableBtn={disableBtn}
				messages={messages?.length}
				justFlag={justFlag}
				handleSubmit={handleSubmit}
				justOpen={justOpen}
				handleJustOpen={handleJustOpen}
				setLoading={setLoading}
				chatbotIsDraft={chatbotIsDraft}
				chatbotId={chatbotId}
				handleFallbackMessage={handleFallbackMessage}
			/>
			}
		</>
	);
})

export default ChatPanel;
