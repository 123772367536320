import { firestore, auth, db } from "../services/firebase/firebase";
import "firebase/firestore";
import firebase from "firebase/app";


export const findDocumentsByField = async (collectionRef, field, value) => {
  try {
    const snapshot = await collectionRef.where(field, '==', value).get();
    return snapshot;
  } catch (error) {
    console.error(`Error fetching documents where ${field} == ${value}:`, error);
    throw error;
  }
};

export const updateDocumentsBatch = async (collectionRef, docs, updates) => {
  try {
    const batch = firestore.batch();
    docs.forEach(doc => {
      const docRef = collectionRef.doc(doc.id);
      batch.update(docRef, updates);
    });
    await batch.commit();
    console.log(`Successfully updated ${docs.length} document(s).`);
  } catch (error) {
    console.error("Error updating documents batch:", error);
    throw error;
  }
};

export const updateRequestCancelled = async (messagesRef, questionRef, messageId) => {
	try {
    if (!messagesRef || !questionRef || !messageId) {
      console.error("Invalid parameters for updateRequestCancelled.");
      return;
    }
  const batch = firestore.batch();

  const questionRefDoc = messagesRef.doc(questionRef);
  batch.update(questionRefDoc, { requestCancelled: true });

  const messageIdDoc = messagesRef.doc(messageId);
  batch.update(messageIdDoc, { requestCancelled: true });

  await batch.commit();
	console.log(`Successfully updated 'requestCancelled' to true for messageId: ${messageId} and questionRef: ${questionRef}`);
  } catch (error) {
    console.error("Error in updateRequestCancelled:", error);
    throw error; // Rethrow to allow calling functions to handle it
  }
};
