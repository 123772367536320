import { firestore } from "../services/firebase/firebase";
import "firebase/firestore";
import firebase from "firebase/app";

export default async function writeForm(userId, chatbotId) {
	const tmpRoomId = userId + "***" + chatbotId
	const chatbotid = chatbotId ? chatbotId : "1001"

	firestore
		.collection("chatbots")
		.doc(chatbotid)
		.get()
		.then(async (res) => {
			const chatbotDocVal = res.data()
			const chatbotName = chatbotDocVal.name;
			// Add form to the conversation
			firestore.collection("rooms").doc(tmpRoomId).collection("messages").add(
				{
					uid: null, //agent,
					// createdAt: new Date(), //firestore.FieldValue.serverTimestamp(),
					createdAt: firebase.firestore.FieldValue.serverTimestamp(),
					form: true,
					chatbotName: chatbotName,
					// isActingAsFinalResponse: true, //to prevent adding the fallBackMessage
				})
				.then(() => {
					firestore.collection("rooms").doc(tmpRoomId).collection("messages").add(
						{
							uid: null, //agent,
							// createdAt: new Date(), //firestore.FieldValue.serverTimestamp(),
							createdAt: firebase.firestore.FieldValue.serverTimestamp(),
							// text: 'You must complete the form before making any requests.',
							text: 'Please complete the form before making any requests.',
							chatbotName: chatbotName,
							isTimeKeeper: false,
							// isActingAsFinalResponse: true, //to prevent adding the fallBackMessage
						})
				}).catch((error) => {
					// Error callback for adding the message
					console.log("Error adding message:", error);
				});
		}).catch((error) => {
			// An error ocurred
			console.log("writeForm ERRR", error)
		});
};
