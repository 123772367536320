import { useState, forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useStateValue } from "../context/state/provider";
import Navbar from "./Navbar";
import ChatFooter from "../components/ChatFooter";
import ChatPanel from "./ChatPanel";
import AccessibilityPanel from "./AccessibilityPanel";
import LoginPanel from "./LoginPanel";
import SettingsPanel from "./SettingsPanel";
import { VIEW } from "../constants/view.constants";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles({
	chatBotBody: {
		position: "static",
		width: "100%",
		top: "0px",
		left: 0,
		right: 0,
		bottom: "118px",
		paddingTop: "90px",
		// paddingTop: "10px", // to use with chatopen when no header bar is present
		borderRadius: "0 0 6px 6px",
		backgroundColor: "#ffffff",
		overflow: "hidden",
		textAlign: "center",
		animation: `fadeIn 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94)`,
		display: "flex",
		flexDirection: "column",
		// height: "calc(100% - 170px)" //commented Sept11 2023
		height: "calc(100% - 105px)" // to use with chatopen when no open/close button is present and header bar present
		// height: "calc(100% - 30px)" // to use with chatopen when no open/close button is present and header bar not present
	},
	'@media (max-height: 370px)': {
		chatBotBody: {
			bottom: "35px",
		}
	},
	'@media (max-height: 320px)': {
		chatBotBody: {
			bottom: "5px",
		}
	},
	'@media (max-height: 180px)': {
		chatBotBody: {
			top: "-80px",
		}
	},
	chatFooter: {
		position: "fixed",
		bottom: "0px",
		right: 0,
		left: 0,
		height: "2opx",
		fontSize: "12px",
		color: "var(--color-light-grey)",
	},
	alert: {
		position: "absolute",
		bottom: "0px", //"80px"
		right: 0,
		left: 0,
		height: "25px",
		fontSize: "12px",
		margin: "auto",
		zIndex: "5",

		"& div:first-child": {
			padding: "0",
			alignItems: "center",
		},

		"& div:last-child": {
			alignSelf: "center",
		},

		svg: {
			alignItems: "center",
		},
	},
});

const ChatbotBody = forwardRef(({
	messages,
	messagesRef,
	brandingTitle,
	subtitle,
	footer,
	avatarUrl,
	firestore,
	error,
	online,
	fsLoading,
	userLoading,
	justOpen,
	handleJustOpen,
	chatbotIsDraft,
	user,
	userLoadingAU,
	chatbotId,
	handleFallbackMessage
}, ref) => {
	const classes = useStyles();
	const [{ view, loggingOut }] = useStateValue();
	const [errMsg, setErrMsg] = useState({ message: "", location: "" });
	const [showErrorMessage, setShowErrorMessage] = useState(false);

	return (
		<div className={classes.chatBotBody}>
			<Navbar
				firestore={firestore}
				view={view}
				brandingTitle={brandingTitle}
				subtitle={subtitle}
				avatarUrl={avatarUrl}
				user={user}
				chatbotId={chatbotId}
			/>

			{view === VIEW.CHATROOM && (
				<ChatPanel
					ref={ref}
					firestore={firestore}
					messagesRef={messagesRef}
					messages={messages}
					fsLoading={fsLoading}
					fsError={error}
					online={online}
					userLoading={userLoading}
					setErrMsg={setErrMsg}
					setShowErrorMessage={setShowErrorMessage}
					disableBtn={showErrorMessage}
					justOpen={justOpen}
					handleJustOpen={handleJustOpen}
					chatbotIsDraft={chatbotIsDraft}
					user={user}
					userLoadingAU={userLoadingAU}
					chatbotId={chatbotId}
					avatarUrl={avatarUrl}
					handleFallbackMessage={handleFallbackMessage}
				/>
			)}
			{view === VIEW.ACCESSIBILITY && <AccessibilityPanel />}
			{view === VIEW.LOGIN && <LoginPanel firestore={firestore} chatbotId={chatbotId} />}
			{view === VIEW.SETTINGS && <SettingsPanel />}

			{showErrorMessage && errMsg.location === "msgInput" && view === VIEW.CHATROOM && !loggingOut && (
				<Alert className={classes.alert} severity="error">
					{errMsg.message}
				</Alert>
			)}
			<ChatFooter footer={footer}/>
		</div>
	);
})

export default ChatbotBody;
