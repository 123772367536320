import { makeStyles } from "@material-ui/core/styles";
import { useStateValue } from "../context/state/provider";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import BackIcon from "../images/backIcon.svg";
import { VIEW } from "../constants/view.constants";

const useStyles = makeStyles({
  avatar: props => ({
    // backgroundImage: props.avatarUrl && props.avatarUrl.trim() !== ""
    //   ? `url(${props.avatarUrl})`
    //   : "var(--active-avatar-url)",
		backgroundImage: props.avatarUrl ? `url("${props.avatarUrl}")` : "var(--active-avatar-url)",
    backgroundPosition: "center",
    backgroundSize: "contain",
    borderRadius: "50%",
    height: "45px",
    width: "45px",
    marginRight: "10px",
    animation: "fadeIn 0.15s ease",
  }),
	backButton: {
		borderRadius: "9px",
		height: "47px",
		width: "47px",
		marginRight: "10px",
		"&:focus": {
			background: "rgba(0,0,0,0.2)",
		},
		"&:hover": {
			background: "transparent",
			opacity: "0.8",
		},
	},
	flexRow: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		animation: "fadeIn 0.15s ease",
	},
	botStatus: {
		color: "var(--bot-status)",
		fontSize: "10px",
		margin: "3px 5px 0 5px",
	},
	botTitle: {
		fontSize: "17px",
		margin: 0,
		maxWidth: "179px",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		textAlign: "left",
	},
	accessibilityTitle: {
		fontSize: "18px",
		fontWeight: 400,
		textAlign: "left",
		animation: "fadeIn 0.15s ease",
	},
	subTitle: {
		fontSize: "13px",
		margin: 0,
		fontWeight: 400,
		textAlign: "start",
		animation: "fadeIn 0.15s ease",
	},
	"@media (max-width: 375px)": {
		botTitle: {
			fontSize: "15px",
			maxWidth: "165px",
		},
		accessibilityTitle: {
			fontSize: "15px",
			marginRight: "5px",
		},
		subTitle: {
			fontSize: "12px",
		},
		avatar: {
			height: "40px",
			width: "40px",
		},
	},
});

export default function HeaderTitle({ brandingTitle, subtitle, avatarUrl }) {
	const classes = useStyles({ avatarUrl });
	const [{ view }, dispatch] = useStateValue();

	const handleBackButton = () => {
		dispatch({
			type: "SET_VIEW",
			view: VIEW.CHATROOM,
		});
	};

	return (
		<div className={classes.flexRow}>
			{view === VIEW.CHATROOM ? (
				// <div className={classes.avatar} />
				<div 
				className={classes.avatar} 
				// onError={handleAvatarError}
				role="img"
				aria-label="Avatar"
			/>
			) : (
				<Tooltip title="back">
					<IconButton
						className={classes.backButton}
						onClick={() => {
							handleBackButton();
						}}
					>
						<img src={BackIcon} alt="back" />
					</IconButton>
				</Tooltip>
			)}
			{view === VIEW.CHATROOM && (
				<div>
					<div className={classes.flexRow}>
						<h1 className={classes.botTitle}>{brandingTitle}</h1>
						<FiberManualRecordIcon className={classes.botStatus} />
					</div>
					{subtitle && <h2 className={classes.subTitle}>{subtitle} </h2>}
				</div>
			)}
			{view === VIEW.ACCESSIBILITY && (
				<div>
					<h1 className={classes.accessibilityTitle}>
						Accessibility Options
					</h1>
				</div>
			)}

			{view === VIEW.SETTINGS && (
				<div>
					<h1 className={classes.accessibilityTitle}>
						Custom Settings
					</h1>
				</div>
			)}
			{view === VIEW.LOGIN && (
				<div>
					<h1 className={classes.accessibilityTitle}>
						Account Login
					</h1>
				</div>
			)}
		</div>
	);
}
