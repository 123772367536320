import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useStateValue } from "../context/state/provider";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LockIcon from "../images/lockIcon.svg";
import sailLogoWhite from "../images/sailLogoWhite.svg";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { functions } from "../services/firebase/firebase";
import { VIEW } from "../constants/view.constants";
import Loader from "react-loader-spinner";
import axios from "axios";
import querystring from "querystring";
import firebase from "firebase/app";
import { signInWithGoogle, db, firestore } from "../services/firebase/firebase";
import { graphConfig } from "../services/firebase/authConfig";
import { FaMicrosoft } from "react-icons/fa";
import Divider from "@material-ui/core/Divider";
import { loginRequest } from "../helpers/authConfig";
import { useMsal } from "@azure/msal-react";
import { getDocs } from 'firebase/firestore';

const useStyles = makeStyles({
	loginPanel: {
		position: "absolute",
		overflowY: "auto",
		overflowX: "hidden",
		top: "75px",
		bottom: "40px",
		left: 0,
		right: 0,
		animation: "fadeInAnimation 0.4s ease",
		padding: "5px ",
	},
	paper: {
		marginTop: "0.5rem",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	loadingContainer: {
		marginTop: "100px",
		color: "var(--color-dark-grey)",
		fontSize: "18px",
		animation: "fadeInAnimation 0.5s ease-out",
	},
	avatar: {
		backgroundColor: "var(--color-light-grey)",
		margin: "5px auto",
		width: "30px",
		height: "30px",
	},
	headerIcon: {
		width: "20px",
		height: "20px",
	},
	form: {
		background: "var(--bot-msg-bg)",
		margin: "0px 30px",
		padding: "10px 5px",
		width: "85%",
		borderRadius: "6px",
		boxShadow: "rgba(149, 157, 165, 0.6) 0px 0px 10px",
	},
	submit: {
		width: "80%",
		height: "35px",
		margin: "15px 0 20px 0",
		background: "var(--active-msg-bg)",
		fontSize: "calc(var(--active-font-size) - 2px)",
		"&:hover": {
			opacity: "0.9",
			background: "var(--active-msg-bg)",
		},
		"&:focus": {
			outline: "none",
			boxShadow: "0 0 0px 2px  var(--active-brand-color)",
		},
	},
	inputs: {
		fontSize: "calc(var(--active-font-size) - 2px)",
		margin: "8px",
		width: "80%",
		height: "35px",
		border: "1px solid grey",
		borderRadius: "35px",
		borderColor: "grey",
		background: "white",
		padding: "14px",
		"&.Mui-focused ": {
			border: "1.5px solid var(--active-brand-color)",
		},
	},
	innerInput: {
		WebkitBoxShadow: "0 0 0 1000px white inset",
		padding: "5px",
	},
	inputLabel: {
		color: "var(--color-dark-grey)",
		fontSize: "calc(var(--active-font-size) - 2px)",
		fontWeight: "500",
		textAlign: "start",
		marginLeft: "15%",
		marginTop: "12px",
	},
	heading: {
		fontWeight: 400,
		color: "var(--color-dark-grey)",
		fontSize: "calc(var(--active-font-size) + 2px)",
		margin: "0px auto 25px auto",
	},
	lock: {
		marginRight: "10px",
		height: "16px",
		width: "16px",
	},
	lockDisabled: {
		marginRight: "10px",
		height: "16px",
		width: "16px",
		opacity: "0.5"
	},
	googleBtn: {
		background: "white",
		margin: "15px 0 15px 0",
		borderColor: "var(--color-dark-grey)",
		color: "var(--color-dark-grey)",
		minWidth: "fit-content",
		"&:hover": {
			opacity: "0.8",
			background: "white",
		},
	},
	errorMsg: {
		color: "#D8000C",
		fontSize: "12px",
	},
	"@media (max-width: 350px)": {
		inputs: {
			width: "90%",
		},
		submit: {
			width: "90%",
			fontSize: "calc(var(--active-font-size) - 2px)",
		},
		googleBtn: {
			fontSize: "calc(var(--active-font-size) - 2px)",
		},
		form: {
			width: "90%",
		},
	},
});

export default function LoginPanel({ firestore, chatbotId }) {
	// Create a Broadcast Channel with a unique name
	// const broadcastChannelLogin = new BroadcastChannel('clientLoginChannel');
	const classes = useStyles();
	const [{ user }, dispatch] = useStateValue();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState(false);
	const [unauthorized, setUnauthorized] = useState(false);
	const [loginLoading, setLoginLoading] = useState(false);
	const { instance, accounts } = useMsal();
	const [loginWithSail, setLoginWithSail] = useState(false);
	const [hasMSLogin, setHasMSLogin] = useState(false);

	useEffect(() => {
    const checkChatbotIdMatch = async () => {
      try {
        const chatbotsMSRef = firestore.collection("chatbotsMSlogin").doc(chatbotId);
        const doc = await chatbotsMSRef.get();
				console.log("Document snapshot:", doc); // Log the document snapshot
        console.log("Document data:", doc.data()); // Log document data (should be undefined if empty)
        if (doc.exists) {
          setHasMSLogin(true); // Match found
        } else {
          setHasMSLogin(false); // No match found
        }
      } catch (error) {
        console.error("Error checking chatbotId:", error);
      }
    };

    checkChatbotIdMatch();
  }, [chatbotId]);

	const handleLogin = async (loginType) => {
		// const prevId = user.id;
		// const prevUser = firebase.auth().currentUser;
		// setLoginLoading(true);

		try {
			if (loginType === "redirect") {
				console.log("MS login popup");
				dispatch({
					type: "SET_VIEW",
					view: VIEW.LOGINTMP,
				});
				if (instance.getAllAccounts().length > 0) {
					const response = await instance.acquireTokenSilent({
						...loginRequest,
						account: accounts[0],
					});
					console.log("MS logout response", response)
				}
				const loginResult = await instance.loginRedirect(loginRequest);

				// console.log("MS login data:", loginResult);
				// console.log("MS accesToken:", loginResult.accessToken);
				// const url = "https://service.premisehq.co:8005/api/sail-user-create"; // with roles
				// const email = loginResult.account.username;
				// const uid = loginResult.uniqueId;
				// // Assign the first part to firstName and the second to lastName
				// const firstName = loginResult.account.name; //nameParts[0];
				// const lastName = ""; //nameParts[1];
				// const dataReq = {
				// 	oid: loginResult.account.idTokenClaims.oid, //required,
				// 	firstName: firstName, //empty string if value is not present
				// 	lastName: lastName, //empty string if value is not present
				// 	phoneWork: "", //empty string if value is not present
				// 	email: email, //empty string if value is not present
				// 	language: "EN", //required
				// 	gender: "male", //required
				// 	birthDate: "", //empty string if value is not present
				// };
				// const config = {
				// 	headers: {
				// 		'Authorization': `Bearer ${loginResult.accessToken}`, // Replace 'your_access_token_here' with the actual token
				// 		'Content-Type': 'application/json'
				// 	}
				// };

				// try {
				// 	// const response = await axios.post(url, dataReq, config);
				// 	const response = {
				// 		data: {
				// 			roles: [
				// 				"General",
				// 				"SAIL Admin"
				// 			]
				// 		}
				// 	}
				// 	console.log('User Created Successfully:', response);
				// 	console.log('User roles:', response.data.roles);
				// 	const rules = response.data.roles;
				// 	const access = rules.includes("SAIL Admin")
				// 	console.log('access', access);

				// 	const prevId = user.id;
				// 	const prevUser = firebase.auth().currentUser;
				// 	setLoginLoading(true);

				// 	// Check if the user with the provided email exists but is not authenticated
				// 	const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(email);
				// 	let userNew;
				// 	if (signInMethods.length === 0) {
				// 		// User does not exist, create the new user by the dedicated API if this user need use Sail
				// 		console.log('User does not exist');

				// 		// User does not exist, create the new user
				// 		const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, uid);
				// 		// Registration successful. You can access the new user's information from userCredential.user
				// 		await firebase
				// 			.auth()
				// 			.currentUser.updateProfile({
				// 				displayName: email,
				// 			});
				// 		userNew = userCredential.user;
				// 		console.log('Registered user:', userNew);
				// 		// Set the displayName property of the user
				// 		try {
				// 			console.log("Display name set successfully!");
				// 		} catch (error) {
				// 			console.error("Error setting display name:", error.message);
				// 		}
				// 		try {
				// 			// Refresh the user's data after adding the claim
				// 			await firebase.auth().currentUser.reload();
				// 		} catch (error) {
				// 			console.error("Error setting display name:", error.message);
				// 		}
				// 	} else {
				// 		// User exists, sign in the user
				// 		console.log('uid', uid);
				// 		const userCredential = await firebase.auth().signInWithEmailAndPassword(email, uid);
				// 		// Login successful. You can access the user's information from userCredential.user
				// 		await firebase
				// 			.auth()
				// 			.currentUser.updateProfile({
				// 				displayName: email,
				// 			});
				// 		userNew = userCredential.user;
				// 		console.log('Logged in user:', userNew);
				// 	}

				// 	//From here use user from firebase to set the chatbot value
				// 	console.log("chatbotId, ", chatbotId)
				// 	//get userID
				// 	console.log("userNew", userNew)
				// 	console.log("userId", userNew.uid)
				// 	const userId = userNew.uid;
				// 	const userEmail = userNew.email;
				// 	console.log("userEmail", userEmail)
				// 	const snapShot = await firestore
				// 		.collection("rooms")
				// 		.doc(userId + "***" + chatbotId) //userId
				// 		.get();

				// 	if (!snapShot.exists) {
				// 		console.log(" room does not exist");
				// 		console.log("userId", userId);
				// 		await firestore
				// 			.collection("rooms")
				// 			.doc(userId + "***" + chatbotId)
				// 			.set({
				// 				pendingMessages: 0,
				// 				chatbotId: chatbotId,
				// 				userEmail: userEmail,
				// 				email: userEmail,
				// 				userName: firstName,
				// 				userFirstName: firstName,
				// 				userId: userId,
				// 				access: access ? access : false,
				// 			}, { merge: true });
				// 	} else {
				// 		//to make sure that a login user will always have a name and email
				// 		//room already exist but re-write userEmail and userName
				// 		console.log(" room ALREADY EXIST ");
				// 		await firestore
				// 			.collection("rooms")
				// 			.doc(userId + "***" + chatbotId)
				// 			.set({
				// 				userEmail: userEmail,
				// 				email: userEmail,
				// 				userName: firstName,
				// 				userFirstName: firstName,
				// 				chatbotId: chatbotId,
				// 				userId: userId,
				// 				access: access ? access : false,
				// 			}, { merge: true });
				// 	}

				// 	// After successful login
				// 	// Update user status to true (online)
				// 	const clientId = chatbotId
				// 	const userRef = db.ref(`rooms/${userId}/clientIds/${clientId}`);
				// 	userRef.set(true).catch(error => {
				// 		console.error('Error updating user status:', error);
				// 	});

				// 	//***WARNING when running the emulator httpsCallable will not work properly and will generete an error */
				// 	await functions.httpsCallable(
				// 		"transferDelete"
				// 	)({
				// 		prevId: prevId,
				// 		userId: userId,
				// 		chatbotId: chatbotId
				// 	});

				// 	//After the login is completed, before to change view
				// 	//delete the previous anonymous user
				// 	console.log("prevId", prevId)
				// 	console.log("prevUser", prevUser)
				// 	if (prevId !== userId) {
				// 		prevUser.delete().then(() => {
				// 			// User deleted.
				// 			console.log("User deleted" + prevId)
				// 			setLoginLoading(false);
				// 			console.log("user", userNew)
				// 			console.log("firstName", firstName)
				// 			console.log("userId", userId)

				// 			dispatch({
				// 				type: "USER",
				// 				user: {
				// 					...userNew,
				// 					name: firstName,
				// 					id: userId,
				// 					loggedIn: true,
				// 				},
				// 			});
				// 			dispatch({
				// 				type: "SET_VIEW",
				// 				view: VIEW.CHATROOM,
				// 			});
				// 		}).catch((error) => {
				// 			// An error ocurred
				// 			console.log("User deleted ERRR: ", error)
				// 		});
				// 	} else {
				// 		setLoginLoading(false);
				// 		console.log("user", userNew)
				// 		console.log("firstName", firstName)
				// 		console.log("userId", userId)
				// 		dispatch({
				// 			type: "USER",
				// 			user: {
				// 				...userNew,
				// 				name: firstName,
				// 				id: userId,
				// 				loggedIn: true,
				// 			},
				// 		});
				// 		dispatch({
				// 			type: "SET_VIEW",
				// 			view: VIEW.CHATROOM,
				// 		});
				// 	}
				// } catch (error) {
				// 	console.error('Error creating user:', error.response ? error.response.data : error.message);
				// 	// Handle error here
				// }
			}
		} catch (e) {
			console.log(e); // Log the entire error object to understand its structure
			// Use e.errorCode or e.message depending on the structure of your error object
			if (e.errorCode && e.errorCode === 'user_cancelled') {
				console.log("User cancelled the login process.");
				dispatch({
					type: "SET_VIEW",
					view: VIEW.CHATROOM,
				});
				return;
			} else if (e.message && e.message.includes("User cancelled the flow.")) {
				console.log("User cancelled the login process via message.");
				dispatch({
					type: "SET_VIEW",
					view: VIEW.CHATROOM,
				});
				return;
			} else {
				// Handle other errors or rethrow them
				console.error("An unexpected error occurred:", e);
				dispatch({
					type: "SET_VIEW",
					view: VIEW.CHATROOM,
				});
				throw e;
			}
		}
	}

	const handleEmailLogin = (e) => {
		e.preventDefault();
		setError(false);
		const prevId = user.id;
		const prevUser = firebase.auth().currentUser;
		let userId;
		let userName;
		let key;
		let appKey;

		const _data = {
			UserName: email,
			Password: password,
		};

		//Get JWT from Premise
		axios
			.post(
				"https://premisehq.co/api/AppLogin/Login",
				querystring.stringify(_data),
				{ headers: { "Content-Type": "application/x-www-form-urlencoded" } }
			)
			.then((res) => {
				console.log("RES from Login line 189", res);
				let result = res.data;
				var status = result.Status;
				if (status !== "Success") {
					setError(true);
				} else {
					setLoginLoading(true);
					const userEmail = result.Email;
					const userUid = result.UserId;
					key = result.Key;
					appKey = result.AppKey;

					//Get Custom Token From Admin SDK
					//"http://localhost:5001/sail-staging-4dcfb/us-central1/app/login", //EMULATOR
					console.log("userUid", userUid)
					axios({
						method: "post",
						url: `${process.env.REACT_APP_FUNCTIONS_BASE_URL}/app/login`,
						data: {
							uid: userUid,
						},
							headers: {
								'appkey': appKey,
								'key': key,
								'Content-Type': 'application/json',
						},
					}).then(async (res) => {
						console.log("RES LOGIN:", res.data);
						let customToken = res.data;
						await firebase
							.auth()
							.signInWithCustomToken(customToken)
							.then(async (result) => {
								console.log("result of login by email", result)
								await firebase
									.auth()
									.currentUser.updateProfile({
										displayName: userEmail,
										email: userEmail,
									});
								console.log("updateProfile")
								userId = userUid;
								userName = userEmail;
								console.log("chatbotId" + chatbotId)
								console.log("userId" + userId)
								const snapShot = await firestore
									.collection("rooms")
									.doc(userId + "***" + chatbotId) //userId
									.get();

								if (!snapShot.exists) {
									console.log("userId", userId);
									await firestore
										.collection("rooms")
										.doc(userId + "***" + chatbotId)
										.set({
											pendingMessages: 0,
											chatbotId: chatbotId,
											// userEmail: userEmail,
											// email: userEmail,
											// userName: userName,
											// userFirstName: userName,

											userEmail: userEmail,
											userEmail_lowercase: userEmail.toLowerCase(),
											email: userEmail,
											email_lowercase: userEmail.toLowerCase(),
											userName: userName,
											userName_lowercase: userName.toLowerCase(),
											userFirstName: userName,
											userFirstName_lowercase: userName.toLowerCase(),

											key: key,
											appKey: appKey,
											userId: userId,
											isAdmin: true,
										}, { merge: true });
								} else {
									//to make sure that a login user will always have a name and email
									//room already exist but re-write userEmail and userName
									await firestore
										.collection("rooms")
										.doc(userId + "***" + chatbotId)
										.set({
											// userEmail: userEmail,
											// email: userEmail,
											// userName: userName,
											// userFirstName: userName,

											userEmail: userEmail,
											userEmail_lowercase: userEmail.toLowerCase(),
											email: userEmail,
											email_lowercase: userEmail.toLowerCase(),
											userName: userName,
											userName_lowercase: userName.toLowerCase(),
											userFirstName: userName,
											userFirstName_lowercase: userName.toLowerCase(),

											chatbotId: chatbotId,
											key: key,
											appKey: appKey,
											userId: userId,
											isAdmin: true,
										}, { merge: true });
								}

								// After successful login
								// Update user status to true (online)
								// const userId = firebase.auth().currentUser.uid;
								const clientId = chatbotId
								const userRef = db.ref(`rooms/${userId}/clientIds/${clientId}`);
								userRef.set(true).catch(error => {
									console.error('Error updating user status:', error);
								});

								//***WARNING when running the emulator httpsCallable will not work properly and will generete an error */
								await functions.httpsCallable(
									"transferDelete"
								)({
									prevId: prevId,
									userId: userId,
									chatbotId: chatbotId,
									firstName: userName //logged in user firsta name
								});

								//After the login is completed, before to change view
								//delete the previous anonymous user
								console.log("prevId", prevId)
								console.log("prevUser", prevUser)
								if (prevId !== userId) {
									prevUser.delete().then(() => {
										// User deleted.
										console.log("User deleted" + prevId)
									}).catch((error) => {
										// An error ocurred
										console.log("User deleted ERRR: ", error)
									});
								}
								setLoginLoading(false);
								dispatch({
									type: "USER",
									user: {
										...user,
										name: userName,
										id: userId,
										loggedIn: true,
									},
								});
								dispatch({
									type: "SET_VIEW",
									view: VIEW.CHATROOM,
								});

								//Make new api call to check flag permission
								axios
									.get(
										`${process.env.REACT_APP_FUNCTIONS_BASE_URL}/app/skills/rooms/${userId}/qaAlerts`,
										{ headers: { key, appKey } }
									)
									.then(async (res) => {
										console.log("Success check", res.data)
										console.log("Success check", res.data.data.isAdmin)
										const isAdmin = res.data.data.isAdmin;
										console.log("userId", userId);
										await firestore
											.collection("rooms")
											.doc(userId + "***" + chatbotId)
											.set({
												isAdmin: isAdmin ? true : false
											}, { merge: true });

										dispatch({
											type: "ADMIN_SETTINGS",
											admin: {
												adminUser: isAdmin ? true : false,
												feedbackOn: isAdmin ? true : false,
											},
										});
									})
							})
							.catch((err) => {
								console.log("error check", err)
							});
					})
						.catch((err) => {
							// setError(true);
							// console.log("email login error: ", err);
							// setLoginLoading(false);
							if(err?.response?.data?.status === "Unauthorized"){
								setUnauthorized(true);
							} else{
								setError(true);
							}
							setLoginLoading(false);
						});
				}
			})
			.catch((err) => {
				// setError(true);
				// console.error(err);
				// setLoginLoading(false);
				if(err?.response?.data?.status === "Unauthorized"){
					setUnauthorized(true);
				} else{
					setError(true);
				}
			setLoginLoading(false);
			});
	};

	//do not use anymore
	const handleGoogleLogin = async (e) => {
		e.preventDefault();
		setError(false);
		const prevId = user.id;
		const prevUser = firebase.auth().currentUser;
		let userId;
		let userName;
		let userEmail;
		const auth = await signInWithGoogle();

		//To handle case when user close the popup from google to select user
		if (auth === undefined || auth.message === "The popup has been closed by the user before finalizing the operation.") {
			return
		}
		setLoginLoading(true);
		userId = auth.currentUser.uid;
		userName = auth.currentUser.displayName;
		userEmail = auth.currentUser.email;
		const snapShot = await firestore.collection("rooms").doc(userId).get();

		if (!snapShot.exists) {
			await firestore.collection("rooms").doc(userId).set({
				pendingMessages: 0,
				chatbotId: JSON.parse(sessionStorage.getItem("CLIENT")).chatbotId.replace(/^"(.*)"$/, '$1'),
				// userEmail: userEmail,
				// userName: userName,
				// userFirstName: userName,
				// email: userEmail,

				userEmail: userEmail,
				userEmail_lowercase: userEmail.toLowerCase(),
				email: userEmail,
				email_lowercase: userEmail.toLowerCase(),
				userName: userName,
				userName_lowercase: userName.toLowerCase(),
				userFirstName: userName,
				userFirstName_lowercase: userName.toLowerCase(),
			}, { merge: true });
		} else {
			//to make sure that a login user will always have a name and email
			//room already exist but re-write userEmail and userName
			await firestore.collection("rooms").doc(userId).set({
				// userEmail: userEmail,
				// userName: userName,
				// userFirstName: userName,
				// email: userEmail,
				
				userEmail: userEmail,
				userEmail_lowercase: userEmail.toLowerCase(),
				email: userEmail,
				email_lowercase: userEmail.toLowerCase(),
				userName: userName,
				userName_lowercase: userName.toLowerCase(),
				userFirstName: userName,
				userFirstName_lowercase: userName.toLowerCase(),

				chatbotId: JSON.parse(sessionStorage.getItem("CLIENT")).chatbotId.replace(/^"(.*)"$/, '$1'),
			}, { merge: true });
		}

		const res = await functions.httpsCallable("transferDelete")({
			prevId: prevId,
			userId: userId,
		});


		prevUser.delete().then(() => {
			// User deleted.
			console.log("User deleted" + prevId)
		}).catch((error) => {
			// An error ocurred
			console.log("User deleted ERRR: ", error)
		});

		setLoginLoading(false);
		dispatch({
			type: "USER",
			user: { ...user, name: userName, id: userId, loggedIn: true },
		});
		dispatch({
			type: "SET_VIEW",
			view: VIEW.CHATROOM,
		});
	};

	return (
		<div className={classes.loginPanel}>
			<div className={classes.paper}>
				{loginLoading ? (
					<div className={classes.loadingContainer}>
						Loading Messages
						<br />
						<br />
						<Loader
							type="Oval"
							color="var(--active-brand-color)"
							height={120}
							width={75}
						></Loader>
					</div>
				) : !loginWithSail ? (
					// Display 2 buttons for login
					<form
						className={classes.form}
						onSubmit={(e) => handleEmailLogin(e)}
						validate={"true"}
					>
						<Avatar className={classes.avatar}>
							<LockOutlinedIcon className={classes.headerIcon} />
						</Avatar>
						<h2 className={classes.heading}>Account Login</h2>
						{error && (
							<p className={classes.errorMsg}>
								A login error occurred. Please try again.{" "}
							</p>
						)}
						{unauthorized && (
							<p className={classes.errorMsg}>
								Unauthorized{" "}
							</p>
						)}
						{ hasMSLogin &&
							// {/* Microsoft login btn */}
							<Button
								fullWidth
								variant="contained"
								color="primary"
								className={`${classes.submit} ${classes.googleBtn}`}
								onClick={() => handleLogin("redirect")}
							>
								<FaMicrosoft
									color="var(--color-dark-grey)"
									className={classes.lock}
								/>
								Continue with Microsoft{" "}
							</Button>
						}
						{/* <br />
						<Divider /> */}
						{/* Sail login btn */}
						<Button
							// disabled={email.length === 0 || password.length === 0 ? true : false}
							// type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
							onClick={() => setLoginWithSail(true)}
						>
							<img
								className={classes.lock}
								src={sailLogoWhite}
								alt="lock icon"
							/>
							Continue with Sail{" "}
						</Button>
					</form>
				) : (
					// Display email and password fields
					<form
						className={classes.form}
						onSubmit={(e) => handleEmailLogin(e)}
						validate={"true"}
					>
						<Avatar className={classes.avatar}>
							<LockOutlinedIcon className={classes.headerIcon} />
						</Avatar>
						<h2 className={classes.heading}>Account Login</h2>
						{error && (
							<p className={classes.errorMsg}>
								A login error occurred. Please try again.{" "}
							</p>
						)}
						{unauthorized && (
							<p className={classes.errorMsg}>
								Unauthorized{" "}
							</p>
						)}
						<InputLabel
							className={classes.inputLabel}
							htmlFor="email"
						>
							Email
						</InputLabel>
						<Input
							className={classes.inputs}
							variant="outlined"
							required
							id="email"
							label="Email Address"
							name="email"
							autoComplete="email"
							autoFocus
							value={email}
							inputProps={{
								className: classes.innerInput,
								maxLength: 100,
								type: "email",
							}}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<InputLabel
							className={classes.inputLabel}
							htmlFor="password"
						>
							Password
						</InputLabel>
						<Input
							className={classes.inputs}
							variant="outlined"
							required
							name="password"
							label="Password"
							type="password"
							id="password"
							value={password}
							inputProps={{
								className: classes.innerInput,
								maxLength: 100,
								type: "password",
							}}
							onChange={(e) => setPassword(e.target.value)}
							autoComplete="current-password"
						/>
						<Button
							disabled={email.length === 0 || password.length === 0 ? true : false}
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
							onClick={(e) => handleEmailLogin(e)}
						>
							<img
								className={email.length === 0 || password.length === 0 ? classes.lockDisabled : classes.lock}
								src={LockIcon}
								alt="lock icon"
							/>
							Login Securely{" "}
						</Button>
					</form>
				)}
			</div>
		</div>
	);
}
