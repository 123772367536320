import { makeStyles } from "@material-ui/core/styles";
import { firestore } from "../services/firebase/firebase";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from '@mui/icons-material/Clear';
import { updateRequestCancelled } from '../helpers/firestoreUtils';

const useStyles = makeStyles({
	feedbackContainer: {
		position: "relative",
		bottom: "-20px",
		marginLeft: "auto",
		display: "flex",
		justifyContent: "flex-end",
	},
	feedbackBtn: {
		border: "2px solid #D32F2F",
		background: "white",
		position: "absolute",
		bottom: "2px",
		right: "-12px",
		height: "20px",
    width: "20px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
		padding: '9px',
    backgroundColor: 'white!important',
		color: "#D32F2F",

		"&:hover": {
			cursor: "pointer",
			opacity: "0.8",
			// borderColor: "#D32F2F", // Darker red on hover
			// color: "#D32F2F", // Darker red on hover
		},

		"&.Mui-disabled": {
			borderColor: "#E57373", // Keep the border reddish when disabled
			color: "#E57373", // Ensure the text and icon color remain reddish
			// opacity: 0.5, // Optional: Add some transparency to indicate the disabled state
		},
	},
	flag: {
		background: "var(--user-msg-txt)",
		border: "1px solid var(--color-alt-red)",
		borderRadius: "5px",
		padding: "1px",
		color: "var(--color-light-red)",
		height: "15px",
		width: "15px",
		transition: "color 0.15s ease",
	},
	flagSelected: {
		color: "var(--bot-status-disconnected)",
	},
});

export default function CancelRequestButton({ messageId,chatbotId, disabled, questionRef, user}) {
	const classes = useStyles();

	const cancelRequestCall = async () => {

		if (!messageId || !questionRef) {
      console.error("messageId or questionRef is not set.");
      return;
    }

		try {
      // Step 1: Fetch room document to get key and appKey
		const messagesRef = firestore
			.collection("rooms")
			.doc(user.uid + "***" + chatbotId)
			.collection("messages");

		await updateRequestCancelled(messagesRef, questionRef, messageId)
	} catch (error) {
		console.error("Error in cancelRequestCall:", error);
		// Optionally, handle errors by notifying the user or retrying
	}
	};

	return (
		<div className={classes.feedbackContainer}>
			<Tooltip title="Cancel request">
				<IconButton
					className={classes.feedbackBtn}
					onClick={() => {
						console.log("Cancel request")
						cancelRequestCall()
					}}
					disabled={disabled}
				>
					<ClearIcon sx={{ fontSize: "16px" }} />
				</IconButton>
			</Tooltip>
		</div>
	);
}
